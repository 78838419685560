import React from 'react';
import Layout from '../../components/mini/layout';
import SEO from '../../components/mini/seo';
import FlexGiftCard from '../../components/mini/flexgiftcard';
import GiftCardBackground from '../../components/giftcardbackground';
import settings from '../../../settings';


// images
const topbanner = `${settings.IMAGES_BASE_URL}/images/mini/giftcards/mini-banner.jpg`;
const holidayBanner = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-holiday.jpg`;
const giftcardMINI = `${settings.IMAGES_BASE_URL}/images/mini/giftcards/gift-card-MINI.jpg`
const GiftCards = () => {
  const getFirstCard = () => {
    const today = new Date();
    const showHolidayBanner = 
      today >= new Date(2024,10,8) && 
      today < new Date(2025,0,1);

    if (showHolidayBanner) {
      return (
        <FlexGiftCard
          rowType="row"
          image={holidayBanner}
          imageAlt="TURBOCHARGE YOUR HOLIDAY"
          header="TURBOCHARGE YOUR HOLIDAY"
          subheader="20% OFF A GIFT CARD"
          text="Create the perfect holiday with some MINI motoring. Get 20% off a gift card, good toward all MINI classes and Experiences."
          btnText="CALL 888-345-4269 TO BOOK"
          btnStyle="btn-black"
          phone="tel:888-345-4269"
        >
          <p>
            Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to book your class today with promo code <span className="bold">24MINIHPH20</span>. 
          </p>
          <p>
            Must purchase gift card by 12/31/24. Valid at our South Carolina and California location.
          </p>
        </FlexGiftCard>
      )
    } else {
      return (
        <FlexGiftCard
        rowType="row"
        image={giftcardMINI}
        imageAlt="Give the gift of speed"
        header="Give the gift of speed"
        subheader="Gift cards available"
        btnText="Call 888-345-4269 to get yours now"
        btnStyle="btn-black"
        phone="tel:888-345-4269"
      >
        <p>Give someone an experience they’ll never forget. Good toward any class, each card is delivered electronically, so it’s also perfect for last-minute gift giving.
        </p>
      </FlexGiftCard>
      )
    }
  }
  return (
    <Layout>
      <SEO title="MINI Driving Experience USA | MINI Driving School" description="Over 50 years of motorsport legacy goes into the MINI Driving Experience in the form of Group Events, Stunt Driving School and autocross and track school." />
      <div className="giftcardpage giftcardpagemini">
          <GiftCardBackground
            imageDesktop={topbanner}
            imageMobile={topbanner}
            imageAlt="BMW Gift Card Top Banner"
          />
          <section className="drivingschool performance-center">
            <div className="container">
              <div className="performance-center__header">
                <h2 >Gift Cards</h2>
              </div>
            </div>
            {getFirstCard()}
          </section>
        </div>
    </Layout>
  );
};

export default GiftCards;
